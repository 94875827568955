import type { MouseEvent } from 'react';
import { useState } from 'react';
import StatusCode from 'status-code-enum';

import { accountsApi } from 'api/accountsApi';

import { fireToast } from 'helpers/Toasts';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import type { useSaveAdArgs } from 'components/Support/UseSaveAd/useSaveAd.typed';

import { useBraze } from 'hooks/useBraze';
import { pushToDataLayer } from 'services/gtmService';

function useSaveAd(args: useSaveAdArgs) {
  const {
    adId,
    userSaved,
    section,
    sellerType,
    trackCreateSaveAd,
    trackDeleteSaveAd,
    sectionName,
    userId,
    authorisationHeader,
    handleLogin,
  } = args;
  const [isAdSaved, setIsAdSaved] = useState(userSaved);
  const [isSaving, setIsSaving] = useState(false);

  const [queueSaveAd, setQueueSaveAd] = useState(false);
  const { logBrazeSaveAdEvent } = useBraze();

  const handleRequest = async (savedStatus: boolean) => {
    try {
      const { status } = savedStatus
        ? await accountsApi.unsaveAd(adId, authorisationHeader)
        : await accountsApi.saveAd(adId, authorisationHeader);

      const requestStatus = status === StatusCode.SuccessOK;
      if (requestStatus) {
        if (!savedStatus) {
          fireToast({
            type: 'SUCCESS',
            text: 'Added to your Saved Ads',
          });
        } else {
          fireToast({
            type: 'SUCCESS',
            text: 'Removed from your Saved Ads',
          });
        }
      }
      return requestStatus;
    } catch (_error) {
      fireToast({
        type: 'ERROR',
        text: 'Oops! Something went wrong, please try again later',
      });
      return false;
    }
  };

  const triggerToggleSave = async () => {
    setIsSaving(true);
    const isOk = await handleRequest(isAdSaved);
    setIsSaving(false);
    if (isOk) {
      setIsAdSaved && setIsAdSaved(!isAdSaved);
      if (!isAdSaved) {
        if (trackCreateSaveAd) {
          trackCreateSaveAd(adId ?? 0);
          logBrazeSaveAdEvent(sectionName, sellerType);
        } else {
          pushToDataLayer({
            event: 'create_saved_ad',
          });
          logBrazeSaveAdEvent(section, sellerType);
        }
      } else {
        if (trackDeleteSaveAd) {
          trackDeleteSaveAd();
        } else {
          pushToDataLayer({
            event: 'delete_saved_ad',
          });
        }
      }
    }
  };

  const loginAndSave = () => {
    setQueueSaveAd(true);
    handleLogin && handleLogin();
  };

  const onSave = (event: MouseEvent) => {
    event.preventDefault(); // Do no trigger the link if one is wrapped around this component
    event.stopPropagation(); // Do not trigger the click handler if one is included in the parent component
    userId ? triggerToggleSave() : loginAndSave();
  };

  useOnUpdateOnly(() => {
    setIsAdSaved(userSaved);
  }, [adId]);

  useOnUpdateOnly(() => {
    if (!userId) {
      setIsAdSaved(false);
    }
  }, [userId]);

  return {
    isAdSaved,
    queueSaveAd,
    onSave,
    triggerToggleSave,
    setIsAdSaved,
    isSaving,
  };
}

export { useSaveAd };
